import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatFecha'
})
export class FormatFechaPipe implements PipeTransform {

  transform(fecha: string, idioma: string, mostrarTexto?: string): any {

    let fechaStr = '';

    // Día texto
    if (mostrarTexto) {
      fechaStr = moment(fecha).lang(idioma).format('dddd') + '<br/>';
    }

    // Formato: Euskera / Ingles
    if (idioma === 'en' || idioma === 'eu') {
      fechaStr += fecha.replace(/-/g, '/');

    // Formato: Castellano
    } else {
      fechaStr += fecha.substring(8,10) + '/' + fecha.substring(5,7) + '/' + fecha.substring(0,4);
    }

    return fechaStr;
  }

}

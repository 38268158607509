import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalendarioComponent } from './calendario/calendario.component';
import { ReservaAltaComponent } from './reserva-alta/reserva-alta.component';
import { LoginComponent } from './login/login.component';

// Utils:
import { FirestoreUtilComponent } from './firestore-util/firestore-util.component';

const routes: Routes = [
  { path: '', component: CalendarioComponent, runGuardsAndResolvers: 'always' },
  { path: 'es', component: CalendarioComponent, runGuardsAndResolvers: 'always' },
  { path: 'eu', component: CalendarioComponent, runGuardsAndResolvers: 'always' },
  { path: 'en', component: CalendarioComponent, runGuardsAndResolvers: 'always' },
  { path: 'alta', component: ReservaAltaComponent },
  { path: 'alta/:id', component: ReservaAltaComponent },
  { path: 'reserva', component: ReservaAltaComponent },
  { path: 'reserva/:date', component: ReservaAltaComponent },
  { path: 'login', component: LoginComponent },

  //TODO: GUARD CANACTIVATE
  { path: 'firestore', component: FirestoreUtilComponent },
  { path: 'util', component: FirestoreUtilComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../shared/service/auth.service';
import { TranslateService } from '@ngx-translate/core';

declare function changeDatepickerLang(lang);

@Component({
  selector: 'app-barra-idiomas',
  templateUrl: './barra-idiomas.component.html',
  styleUrls: ['./barra-idiomas.component.css']
})
export class BarraIdiomasComponent implements OnInit, OnDestroy {

  @Input() isUserSignedIn: boolean;
  @Output() cambiarLenguaje = new EventEmitter<string>();
  idioma: string;
  subscription: any;


  constructor(private router: Router, public translate: TranslateService, private authService: AuthService) { }

  ngOnInit() {
    this.subscription = this.authService.getSignedInUserEmitter()
      .subscribe(signedIn => this.isUserSignedIn = signedIn);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  // PUBLIC METHODS -----------------------------------------------------------
  public onClickGoToCalendar() {
    this.router.navigate(['/']);
  }

  public onClickGoToReservaEdit() {
    this.router.navigate(['/alta']);
  }

  public onClickGoToLogin() {
    this.router.navigate(['/login']);
  }

  public onClickGoToUtil() {
    this.router.navigate(['/util']);
  }

  public onClickcambiarLenguaje(idioma: string) {
    this.cambiarLenguaje.emit(idioma);
    changeDatepickerLang(idioma);
    // this.router.navigate(['/' + idioma]);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../shared/service/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild('f') formLogin: NgForm;
  public isUserSignedIn: boolean;

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit() {
    this.isUserSignedIn = false;
    this.isUserSignedIn = this.authService.isUserSignedIn();
  }


  // PUBLIC METHODS -----------------------------------------------------------
  public onSubmitLogin() {
    this.signinUser();
  }

  public signOut() {
    this.authService.signoutUser();
  }


  // PRIVATE METHODS ----------------------------------------------------------
  private signinUser() {
    this.authService.signinUser(this.formLogin.value.email, this.formLogin.value.password);
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BarraIdiomasComponent } from './barra-idiomas/barra-idiomas.component';
import { CalendarioComponent } from './calendario/calendario.component';
import { FooterComponent } from './footer/footer.component';
import { CalendarioDiaComponent } from './calendario/calendario-dia/calendario-dia.component';
import { DiaService } from './shared/service/dia.service';
import { ReservaService } from './shared/service/reserva.service';
import { TipoService } from './shared/service/tipo.service';
import { ReservaAltaComponent } from './reserva-alta/reserva-alta.component';
import { LoginComponent } from './login/login.component';

import { Utils } from './shared/utils/utils-idioma';

// Translation
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { environment } from '../environments/environment';
import { FirestoreUtilComponent } from './firestore-util/firestore-util.component';
import { AuthService } from './shared/service/auth.service';
import { FormatFechaPipe } from './shared/pipe/format-fecha.pipe';
import * as firebase from 'firebase/app';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

firebase.initializeApp(environment.firebase);

@NgModule({
  declarations: [
    AppComponent,
    BarraIdiomasComponent,
    CalendarioComponent,
    FooterComponent,
    CalendarioDiaComponent,
    ReservaAltaComponent,
    LoginComponent,
    FirestoreUtilComponent,
    FormatFechaPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [ HttpClient ]
      }
    }),
    PDFExportModule,
    BrowserAnimationsModule
  ],
  providers: [
    DiaService,
    ReservaService,
    TipoService,
    AuthService,
    Utils,
    {provide: FirestoreSettingsToken, useValue: {} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'Azkuna Zentroa - AZ Espazioa';
  public activeLang = 'es';
  public acceptedLangs = ['es', 'eu', 'en'];

  constructor(private translate: TranslateService, private activatedRoute: ActivatedRoute) {
    this.translate.setDefaultLang(this.activeLang);
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.recuperarIdioma(params);
      this.cambiarLenguaje(this.activeLang);
    });
  }


  // PUBLIC METHODS -----------------------------------------------------------
  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    this.translate.use(lang);
  }


  // PRIVATE METHODS ----------------------------------------------------------
  private recuperarIdioma(params: Params) {

    // Se obtiene el idioma si se accede desde la web de Azkuna Zentroa:
    if (document.referrer.indexOf('/az/') > -1) {

      if (document.referrer.indexOf('/cast/') > -1) {
        this.translate.currentLang = 'es';
      } else if (document.referrer.indexOf("/eusk/") > -1) {
        this.translate.currentLang = 'eu';
      } else if (document.referrer.indexOf("/ingl/") > -1) {
        this.translate.currentLang = 'en';
      }

      // Se obtiene el idioma del parametro del parametro 'lang' en la URL:
    } else if (params.lang && this.acceptedLangs.indexOf(params.lang) > -1) {
      this.translate.currentLang = params.lang;
    }
  }
}



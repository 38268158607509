import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { DiaModel } from '../model/dia-model';
import { ReservaService } from './reserva.service';
import { ReservaModel } from '../model/reserva-model';
import { Observable, Observer, Subscription } from 'rxjs';
import * as moment from 'moment';
import { FiltroSemanalService } from './filtro-semanal.service';

@Injectable({
  providedIn: 'root'
})
export class DiaService implements OnInit, OnDestroy {

  public dias: DiaModel[];
  private reservas: ReservaModel[] = [];
  private reservasSubscription: Subscription;
  private diasObservable: Observable<DiaModel[]> = null;

  constructor(private reservaService: ReservaService, private filtroSemanalService: FiltroSemanalService) { }

  ngOnInit(): void { }

  // PUBLIC METHODS -----------------------------------------------------------
  public getListado(): Observable<DiaModel[]> {

    this.dias = [];
    const reservasObservable: Observable<ReservaModel[]> = this.reservaService.readAll();
    let dia = new DiaModel();

    if (this.reservasSubscription !== null && this.reservasSubscription !== undefined) {
      this.reservasSubscription.unsubscribe();
    }

    this.reservasSubscription = reservasObservable.subscribe(
      (data: ReservaModel[]) => {
        this.reservas = data;

        for (const reserva of this.reservas) {

          // Días no disponibles:
          if (reserva.tipoId === 2) {
            dia = new DiaModel(reserva.fecha);
            dia.id = reserva.id;

            // Día nuevo, se añade primera reserva:
          } else if (dia.fecha !== reserva.fecha) {
            dia = new DiaModel();
            dia.tipoId = reserva.tipoId;
            dia.fecha = reserva.fecha;
            dia.reservas = [];
            dia.reservas.push(reserva);
            this.dias.push(dia);

            // Mismo día , se añade siguiente reserva:
          } else {
            dia.reservas.push(reserva);
          }
        }
      });

    this.diasObservable = Observable.create((observer: Observer<DiaModel[]>) => {
      observer.next(this.dias);
    });

    return this.diasObservable;
  }

  //TODO: REFACTOR DEBIDO A DUPLICIDAD DE CODIGO CON getListado()
  public getListadoWeek(fechaSemana?: string, isUserSignedIn?: boolean): Observable<DiaModel[]> {

    const fechaActual = moment();

    fechaSemana = fechaSemana? fechaSemana: this.filtroSemanalService.actualSemana();

    this.dias = this.getDiasSemana(moment(fechaSemana));
    const reservasObservable: Observable<ReservaModel[]> = this.reservaService.readAllWeek(fechaSemana);

    if (this.reservasSubscription !== null && this.reservasSubscription !== undefined) {
      this.reservasSubscription.unsubscribe();
    }

    this.reservasSubscription = reservasObservable.subscribe(
      (data: ReservaModel[]) => {
        this.reservas = data;
        let diasTemp = []

        for (let dia of this.dias) {
          
          let diaTemp = dia;
          diaTemp.reservas = [];

          for (let reserva of this.reservas) {
            if (dia.fecha === reserva.fecha) {
              
              if (reserva.tipoId === 2) { //TODO de momento así hasta quitar la variable tipo
                diaTemp.tipoId = 2;
                diaTemp.id = reserva.id;
                break;
                
              } else {
                diaTemp.tipoId = 0;
                diaTemp.reservas.push(reserva);
              }
            }
          }
          diasTemp.push(diaTemp);
        }
        this.dias = diasTemp;
      });

    this.diasObservable = Observable.create((observer: Observer<DiaModel[]>) => {
      observer.next(this.dias);
    });

    return this.diasObservable;
  }

  ngOnDestroy(): void {
    this.reservasSubscription.unsubscribe();
  }


  // PRIVATE METHODS ----------------------------------------------------------
  private getDiasSemana(primerDiaSemanaActual: any): any {

    let dias: DiaModel[] = [];
    let diaSemana = primerDiaSemanaActual;

    for (let i = 0; i < 7; i++) {
      let dia = new DiaModel();
      dia.fecha = diaSemana.format('YYYY-MM-DD');
      dia.reservas = [];
      dias.push(dia);
      diaSemana.add(1, 'days');
    }

    return dias;
  }
}

import { Renderer2 } from '@angular/core';
import { drawDOM, exportPDF, DrawOptions, Group } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';

declare var jquery:any;
declare var $:any;

export function exportElement(element: HTMLElement, nombre: string, renderer2: Renderer2, desc?: HTMLElement, sinop?: HTMLElement, options?: DrawOptions ) {
    drawDOM(element, options).then((group: Group) => {
        return exportPDF(group);
    }).then((dataUri) => {
        nombre = nombre!=null&&nombre.trim()!=""?"-"+ nombre:"";
        saveAs(dataUri, 'reserva'+ nombre +'.pdf');
        if(desc != undefined){
            renderer2.removeChild(element ,desc);
        }
        if(sinop != undefined) {
            renderer2.removeChild(element ,sinop);
        }

        $('#sinopsisLabel').css('display', '');
        $('#sinopsis').css('display', '');
        $('#botonBusqueda').css('display', '');

    });
}

import * as firebase from 'firebase';
import { Component, OnInit, ViewChild, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/service/auth.service';
import { FiltroSemanalService } from 'src/app/shared/service/filtro-semanal.service';
import * as moment from 'moment';
import { CalendarioDiaComponent } from './calendario-dia/calendario-dia.component';
import { NgForm, FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Utils } from '../shared/utils/utils-idioma';

declare var jquery:any;
declare var $:any;

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.css']
})
export class CalendarioComponent implements OnInit, DoCheck{

  isUserSignedIn: boolean;
  firebaseAppDefined = false;

  primerDiaSemanaActual: any;
  primerDiaSemanaActualStr = '';
  // public semanaFiltroStr: string;
  public filtroSemanaStr: string;
  public local: string;

  @ViewChild(CalendarioDiaComponent) calendarioDiaComponent;


  constructor(private router: Router, private authService: AuthService, private translate: TranslateService,
              private filtroSemanalService: FiltroSemanalService) { }

  ngOnInit() {
    this.isUserSignedIn = false;
    this.isUserSignedIn = this.authService.isUserSignedIn();

    this.primerDiaSemanaActual = this.primerDiaCalendario();
    this.filtroSemanaStr = this.primerDiaSemanaActual.format('YYYY-MM-DD');
    this.calendarioDiaComponent.getListadoweek(this.filtroSemanaStr);
    window.scrollTo(0, 0);
  }

  ngDoCheck() {
    this.marcarCheckBox();
    this.local = this.translate.currentLang;
  }


  // PUBLIC METHODS -----------------------------------------------------------
  public onChangeCalendarioFiltro() {
    if (moment(this.filtroSemanaStr).isValid()) {
      this.filtroSemanaStr = this.filtroSemanalService.filtroSemana(this.filtroSemanaStr);
      this.calendarioDiaComponent.getListadoweek(this.filtroSemanaStr);
    }
  }
  
  public onClickSemanaAnteriorFiltro() {
    this.filtroSemanaStr = this.filtroSemanalService.anteriorSemana(this.filtroSemanaStr);
    this.calendarioDiaComponent.getListadoweek(this.filtroSemanaStr);
    this.filtroSemanaStr = this.filtroSemanaStr;
  }
  
  public onClickSemanaProximaFiltro() {
    this.filtroSemanaStr = this.filtroSemanalService.siguienteSemana(this.filtroSemanaStr);
    this.calendarioDiaComponent.getListadoweek(this.filtroSemanaStr);
    this.filtroSemanaStr = this.filtroSemanaStr;
  }  
  
  // PRIVATE METHODS ----------------------------------------------------------
  private primerDiaCalendario() {
    
    const hoy = moment();
    let primerDiaCalendario = hoy;
    const plazoInicio = moment(environment.plazo.inicio);
    
    if (hoy.isBefore(plazoInicio)) {
      primerDiaCalendario = plazoInicio;
    }

    return primerDiaCalendario;
  }

  private marcarCheckBox(){
    if(this.mostrarReservado) {
      $('#checkboxReservado').prop( "checked", true );
    }
    if(this.mostrarEnCurso) {
      $('#checkboxEnCurso').prop( "checked", true );
    }
    if(this.mostrarDescartado) {
      $('#checkboxDescartado').prop( "checked", true );
    }
    
  }

  private marcarReservado(){
    this.mostrarReservado = this.mostrarReservado == true?false:true;
  }

  private marcarEnCurso(){
    this.mostrarEnCurso = this.mostrarEnCurso == true?false:true;
  }
  
  private marcarDescartado(){
    this.mostrarDescartado = this.mostrarDescartado == true?false:true;
  }

  private activeReservado() {
    if (this.mostrarReservado == true) {
      return 'active';
    } else {
      return '';
    }
  }

  private activeEnCurso() {
    if (this.mostrarEnCurso == true) {
      return 'active';
    } else {
      return '';
    }
  }

  private activeDescartado() {
    if (this.mostrarDescartado == true) {
      return 'active';
    } else {
      return '';
    }
  }

  // GETTER - SETTER --------------------
  get mostrarReservado(): boolean {
    return this.filtroSemanalService.mostrarReservado;
  }
  set mostrarReservado(value: boolean) {
    this.filtroSemanalService.mostrarReservado = value;
  }

  get mostrarEnCurso(): boolean {
    return this.filtroSemanalService.mostrarEnCurso;
  }
  set mostrarEnCurso(value: boolean) {
    this.filtroSemanalService.mostrarEnCurso = value;
  }

  get mostrarDescartado(): boolean {
    return this.filtroSemanalService.mostrarDescartado;
  }
  set mostrarDescartado(value: boolean) {
    this.filtroSemanalService.mostrarDescartado = value;
  }

}

import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FiltroSemanalService {

  // filtro de estado
  mostrarReservado: boolean = true;
  mostrarEnCurso: boolean = true;
  mostrarDescartado: boolean = true;

  constructor() { }

  public anteriorSemana(semanaFiltro: string): string {
    return moment(semanaFiltro).subtract(7, 'days').format('YYYY-MM-DD');
  }

  public filtroSemana(semanaFiltro: string): string {
    return moment(semanaFiltro).format('YYYY-MM-DD');
  }

  public actualSemana(): string {
    return moment().format('YYYY-MM-DD');
  }

  siguienteSemana(semanaFiltro: string): string {
    return moment(semanaFiltro).add(7, 'days').format('YYYY-MM-DD');
  }

}

export class ReservaModel {

  id: string;
  fecha: string;          // YYYYMMDD
  horaInicio: string;     // hhmm
  horaFin: string;        // hhmm

  nombreEntidad: string;
  personaReferencia: string;
  emailContacto: string;
  telefono: string;

  tema: string;
  descripcion: string;
  nAsistentes: number;

  espacioId: number;
  tipoId: number;

  sinopsis: string;
  solicitudId: string;


  constructor(fecha: string, espacioId: number, horaInicio = '', horaFin = '',
    nombreEntidad = '', personaReferencia = '',
    emailContacto = '', telefono = '', tema = '', descripcion = '',
    tipoId = 1, nAsistentes?: number, sinopsis = '', solicitudId = '') {

    this.fecha = fecha;
    this.horaInicio = horaInicio;
    this.horaFin = horaFin;

    this.nombreEntidad = nombreEntidad;
    this.personaReferencia = personaReferencia;
    this.emailContacto = emailContacto;
    this.telefono = telefono;

    this.tema = tema;
    this.descripcion = descripcion;
    this.nAsistentes = nAsistentes;

    this.espacioId = espacioId;
    this.tipoId = tipoId;

    this.sinopsis = sinopsis;
    this.solicitudId = solicitudId;
    
  }

}

import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation, ElementRef, Renderer2 } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ReservaModel } from '../shared/model/reserva-model';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/service/auth.service';
import { NgForm } from '@angular/forms';
import { ReservaService } from '../shared/service/reserva.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { exportElement } from './exportPdf';
import { TipoModel } from '../shared/model/tipo-model';
import { TipoService } from '../shared/service/tipo.service';
import { Utils } from '../shared/utils/utils-idioma';

declare var jquery:any;
declare var $:any;
declare function isNavigatorIE(): boolean;
declare function setHoraFinIE(hora);
declare function setHoraInicioIE(hora);
declare function changeDatepickerLang(lang);

@Component({
  selector: 'app-reserva-alta',
  templateUrl: './reserva-alta.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./reserva-alta.component.css']
})
export class ReservaAltaComponent implements OnInit, AfterViewInit {

  @ViewChild('f') formReservas: NgForm;
  @ViewChild('htmlToPDF') htmlToPDF: ElementRef;
  private id: string;
  public date: string;
  submitted = false;
  model = new ReservaModel('', 0, '', '', '', '', '', '', '', '', 1, 0, '', '');
  tipos: TipoModel[];
  tipo: TipoModel;

  mostrarAvisoError = false;
  mostrarAvisoSuccess = false;
  mostrarFechaNoDisponible = false;
  mostrarHorarioNoDisponible = false;

  isUserSignedIn: boolean;

  isIE: boolean;
  fechaIE: string;
  fechasNoDisponibles: string[];
  fechasNoDisponiblesFijas: string[];
  solicitudId: string;


  minDate = environment.plazo.inicio;
  maxDate = environment.plazo.fin;

  constructor(
    private router: Router, 
    private activeRoute: ActivatedRoute, 
    private authService: AuthService, 
    private reservaService: ReservaService, 
    private tipoService: TipoService,
    private http: HttpClient, 
    private translate: TranslateService, 
    private renderer2: Renderer2,
    private utils: Utils
    ) { }

  ngOnInit() {
    this.model = this.reservaVacia();
    this.id = this.activeRoute.snapshot.params.id;
    this.model.fecha = this.activeRoute.snapshot.queryParams.date;

    this.isUserSignedIn = this.authService.isUserSignedIn();
    this.isIE = isNavigatorIE();
    this.getFechasNoDisponible();

    if (this.id !== '' && this.id !== undefined) {
      this.reservaService.read(this.id)
        .get().subscribe(doc => {
          this.model = JSON.parse(JSON.stringify(doc.data()));
          this.solicitudId = this.model.solicitudId;
        });
    }

    this.tipoService.readAll().subscribe(data => {
      this.tipos = data;
    });

    window.scrollTo(0, 0);
  }

  ngAfterViewInit(){
    $("#fechaIE").datepicker({
      dateFormat: "yy-mm-dd",
      onSelect: (date) => {
        this.model.fecha = date;
      }
    });
    changeDatepickerLang(this.translate.currentLang);
    
    $('#horaInicioIE').timepicker({
      timeFormat: 'HH:mm',
      interval: 15,
      dropdown: true,
      scrollbar: true,
      startTime: '07:00',
      change: (hora) => {
        this.model.horaInicio = moment(hora).format('HH:mm');
      }
    });
    
    $('#horaFinIE').timepicker({
      timeFormat: 'HH:mm',
      interval: 15,
      dropdown: true,
      scrollbar: true,
      startTime: '07:00',
      change: (hora) => {
        this.model.horaFin = moment(hora).format('HH:mm');
      }
    });
  }

  // Events -------------------------------------------------------------------
  public onSubmit() {
    if (this.isFormValid()) {

      let modelDto = this.model;

      // let solicitudId = moment.toString

      if (this.isUserSignedIn) {
        // Alta gestor:
        // if (confirm(this.translate.instant('reserva-alta.gestor.confirmacion.aceptar'))) {
        this.submitted = true;
        modelDto.tipoId = typeof modelDto.tipoId === 'string'?parseInt(modelDto.tipoId):modelDto.tipoId;
        this.reservaService.save(modelDto);
        this.formReservas.reset();
        this.model = new ReservaModel('', 0, '', '', '', '', '', '', '', '', 1, 0, '', '');
        // }

      } else {

        //Solicitud reserva:
        // if (confirm(this.translate.instant('reserva-alta.solicitante.confirmacion.aceptar'))) {

          this.mostrarFechaNoDisponible = false;
          this.mostrarHorarioNoDisponible = false;
          this.submitted = true;
          modelDto.tipoId = 3 // En Curso

          modelDto.solicitudId = this.generarSolicitudId(modelDto);

          this.reservaService.save(modelDto);

          let params = new HttpParams();

          this.http.get(environment.emailSender.url, {
            responseType: 'text',
            params: {
              solicitudId: modelDto.solicitudId,
              fecha: moment(modelDto.fecha).format('DD/MM/YYYY'),
              horario: modelDto.horaInicio + ' - ' + modelDto.horaFin,
              nombreEntidad: modelDto.nombreEntidad,
              personaReferencia: modelDto.personaReferencia,
              emailContacto: modelDto.emailContacto,
              telefono: modelDto.telefono,
              tema: modelDto.tema,
              descripcion: modelDto.descripcion,
              nAsistentes: modelDto.nAsistentes.toString(),
              language: this.translate.currentLang
            }
          })
            // this.http.get(urlWithParams)
            .pipe(map(
              data => { }
            ))
            .subscribe(
              result => {
                console.info(result);
              }
            );

          this.formReservas.reset();
          this.model = new ReservaModel('', 0, '', '', '', '', '', '', '', '', 1, 0, '', '');

        // }
      }

    } else {
      this.submitted = false;
    }
  }

  public onLimpiar() {
    this.formReset();
  }

  public onVolver() {
    this.router.navigate(['']);
  }

  public onChangeTipo() {
    if (this.formReservas.form.controls.tipo.value === 'NoDisponible') {
      this.formReset({ tipo: 'NoDisponible' });
    } else {
      //this.formReset();
    }
  }

  public onClickCerrarAviso() {
    this.mostrarAvisoError = false;
    this.mostrarAvisoSuccess = false;
  }

  public isFechaNoDisponible() {
    let fechasNoDisponiblesFijas = environment.dias.noDisponible;
    let fecha = moment(this.model.fecha);

    if(fecha < moment(environment.plazo.inicio) || moment(environment.plazo.fin) < fecha ||
          this.fechasNoDisponibles.indexOf(this.model.fecha) != -1 || 
          fechasNoDisponiblesFijas.includes(fecha.format('dddd'))
          ) {
            return true;
          } else {
            return false;
          }
  }


  public isHorarioNoDisponible() {

    let noDisponible = false;
   
    let diasTarde = environment.horarioInscripcion.diasTarde;
    let arrDiasTarde = diasTarde.split(",");

    let diasManana = environment.horarioInscripcion.diasManana;
    let arrDiasManana = diasManana.split(",");

    let horaInicioPermitidoTarde = environment.horarioInscripcion.horarioTardeInicio.replace(":", "");
    let horaFinPermitidoTarde = environment.horarioInscripcion.horarioTardeFin.replace(":", "");

    let horaInicioPermitidoManana = environment.horarioInscripcion.horarioMananaInicio.replace(":", "");
    let horaFinPermitidoManana = environment.horarioInscripcion.horarioMananaFin.replace(":", "");

    let horaInicio = this.model.horaInicio!=null?this.model.horaInicio.replace(":", ""):"";
    let horaFin = this.model.horaFin!=null?this.model.horaFin.replace(":", ""):"";
    
    if (horaInicio === "" || horaFin === ""){
      return false;
    }    

    if (arrDiasTarde.includes(this.model.fecha)){     

      let numHoraInicio = Number(horaInicio);
      let numHoraFin = Number(horaFin);

      let numHoraPermInicio = Number(horaInicioPermitidoTarde);
      let numHoraPermFin = Number(horaFinPermitidoTarde);

      if ( (numHoraFin > numHoraInicio) && (numHoraInicio >= numHoraPermInicio && numHoraInicio < numHoraPermFin)  && (numHoraFin > numHoraPermInicio && numHoraFin <= numHoraPermFin)){
        noDisponible = false;
      }else{
        noDisponible = true;
      }
      

    }else if (arrDiasManana.includes(this.model.fecha)){      

      let numHoraInicio = Number(horaInicio);
      let numHoraFin = Number(horaFin);

      let numHoraPermInicio = Number(horaInicioPermitidoManana);
      let numHoraPermFin = Number(horaFinPermitidoManana);

      if ( (numHoraFin > numHoraInicio) && (numHoraInicio >= numHoraPermInicio && numHoraInicio < numHoraPermFin)  && (numHoraFin > numHoraPermInicio && numHoraFin <= numHoraPermFin)){
        noDisponible = false;
      }else{
        noDisponible = true;
      }
    }
    
    return noDisponible;
    
  }

  // Private methods ----------------------------------------------------------
  private formReset(valoresIniciales?: any) {
    this.submitted = false;
    this.mostrarAvisoError = false;
    this.mostrarAvisoSuccess = false;
    this.mostrarFechaNoDisponible = false;
    this.mostrarHorarioNoDisponible = false;
    this.formReservas.reset();

    if (valoresIniciales === null || valoresIniciales === undefined) {
      valoresIniciales = { tipo: 'Disponible' };
    }
    this.formReservas.form.patchValue(valoresIniciales);
  }

  private isFormValid(): boolean {

    let isValid = false;

    this.model.tipoId = typeof this.model.tipoId == 'string'?parseInt(this.model.tipoId):this.model.tipoId;

    if(this.model.tipoId === 2){
      isValid = true;
    }else if (this.formReservas.invalid) {
      Object.keys(this.formReservas.form.controls).forEach((key) => {
        const control = this.formReservas.form.controls[key];
        control.markAsTouched();
      });
      this.mostrarAvisoError = true;
      this.mostrarFechaNoDisponible = false;
      this.mostrarAvisoSuccess = false;
      this.mostrarHorarioNoDisponible = false;

    } else if(this.isFechaNoDisponible()){
      this.mostrarAvisoError = true;
      this.mostrarFechaNoDisponible = true;
      this.mostrarAvisoSuccess = false;
      this.mostrarHorarioNoDisponible = false;
      

    }else if(this.isHorarioNoDisponible()){
      this.mostrarAvisoError = true;
      this.mostrarFechaNoDisponible = false;
      this.mostrarAvisoSuccess = false;
      this.mostrarHorarioNoDisponible = true;

    } else {
      this.mostrarAvisoError = false;
      this.mostrarAvisoSuccess = true;
      //setTimeout(() => {this.onClickCerrarAviso();} , 3000);
      isValid = true;
    }

    return isValid;
  }

  private getFechasNoDisponible() {
    this.fechasNoDisponibles = [];
    this.reservaService.readAllNoDisponible().subscribe(ref =>{
      for(let reserva of ref){
        this.fechasNoDisponibles.push(reserva.fecha);
      }

    })
  }

  private buscarSolicitud() {
    /* let solicitudIdAux = this.solicitudId.split("-");
    let solicitudIdUpper = solicitudIdAux[0] + "-" + solicitudIdAux[1].toLocaleUpperCase(); */
    this.reservaService.readOneBySolicitudId(this.solicitudId).subscribe(solicitud =>{
      this.model = solicitud[0]==undefined?this.reservaVacia():solicitud[0];
    });
  }

  private generarSolicitudId(solicitud: ReservaModel) {
    let parteDia =  moment().format('YYYYMMDD');
    let parteHora =  moment().format('LTS').toString();
    parteHora = parteHora.substr(0, parteHora.length-2);
    let parteHoraAux = parteHora.split(':');
    parteHora = '';
    for( let h of parteHoraAux) {
      parteHora = parteHora + h;
    }
    let primeraLetra = solicitud.nombreEntidad.substr(0,1);
    let segundaLetra = solicitud.personaReferencia.substr(0,1);
    let terceraLetra = solicitud.emailContacto.substr(0,1);

    let solicitudId = parteDia.substr(4) + parteHora.trim() + '-' + primeraLetra.toUpperCase() + segundaLetra.toUpperCase() + terceraLetra.toUpperCase();

    return solicitudId;
  }

  private reservaVacia(){
    return new ReservaModel('', 0, '', '', '', '', '', '', '', '', 1, 0, '', '');
  }

  private exportPDF(){
    let pdf = this.htmlToPDF.nativeElement;
    let descDiv1;
    let sinopDiv1;

    // descripcion
    if(this.model.descripcion != undefined && this.model.descripcion != ""){
      descDiv1 = this.renderer2.createElement('div');
      this.renderer2.addClass(descDiv1, "row");
      this.renderer2.addClass(descDiv1, "vertical-space-min");
      this.renderer2.addClass(descDiv1, "page-break");
      
      let descDiv2 = this.renderer2.createElement('div');
      this.renderer2.addClass(descDiv2, "large-8");
      this.renderer2.addClass(descDiv2, "columns");
      
      let descLabel = this.renderer2.createElement('label');
      let descLabelText = this.renderer2.createText(this.translate.instant('reserva-alta.formulario.label.descripcion'));
      
      let descSpan = this.renderer2.createElement('span');
      this.renderer2.addClass(descSpan, "espacio-span");
      let descSpanText = this.renderer2.createText(this.model.descripcion); //.replace(/\r?\n/g, "\n"));
      
      this.renderer2.appendChild(descSpan, descSpanText);
      this.renderer2.appendChild(descLabel, descLabelText);
      this.renderer2.appendChild(descDiv2, descLabel);
      this.renderer2.appendChild(descDiv2, descSpan);
      this.renderer2.appendChild(descDiv1, descDiv2);
      
      this.renderer2.appendChild(pdf, descDiv1);
    }

    // sinopsis
    if(this.model.sinopsis != undefined && this.model.sinopsis != ""){
      sinopDiv1 = this.renderer2.createElement('div');
      this.renderer2.addClass(sinopDiv1, "row");
      this.renderer2.addClass(sinopDiv1, "vertical-space-min");
      this.renderer2.addClass(sinopDiv1, "page-break");
      
      let sinopDiv2 = this.renderer2.createElement('div');
      this.renderer2.addClass(sinopDiv2, "large-8");
      this.renderer2.addClass(sinopDiv2, "columns");
      
      let sinopLabel = this.renderer2.createElement('label');
      let sinopLabelText = this.renderer2.createText(this.translate.instant('reserva-alta.formulario.label.sinopsis'));
      
      let sinopSpan = this.renderer2.createElement('span');
      this.renderer2.addClass(sinopSpan, "espacio-span");
      let sinopSpanText = this.renderer2.createText(this.model.sinopsis); //.replace(/\r?\n/g, "\n"));
      
      this.renderer2.appendChild(sinopSpan, sinopSpanText);
      this.renderer2.appendChild(sinopLabel, sinopLabelText);
      this.renderer2.appendChild(sinopDiv2, sinopLabel);
      this.renderer2.appendChild(sinopDiv2, sinopSpan);
      this.renderer2.appendChild(sinopDiv1, sinopDiv2);
      
      this.renderer2.appendChild(pdf, sinopDiv1);
    }
    

    const options = {
      paperSize: "A3",
      margin:{
        bottom: "2cm",
        left: "2cm",
        right: "2cm",
        top: "2cm"
      },
      keepTogether: ".page-break"
    };

    $('#sinopsisLabel').css('display', 'none');
    $('#sinopsis').css('display', 'none');
    $('#botonBusqueda').css('display', 'none');
    
    exportElement(pdf, this.model.tema, this.renderer2, descDiv1, sinopDiv1, options);
  }
}

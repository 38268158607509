import { Component, OnInit, Input } from '@angular/core';

import { DiaModel } from 'src/app/shared/model/dia-model';
import { DiaService } from 'src/app/shared/service/dia.service';
import { ReservaModel } from 'src/app/shared/model/reserva-model';
import { ReservaService } from 'src/app/shared/service/reserva.service';
import { FiltroSemanalService } from 'src/app/shared/service/filtro-semanal.service';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/service/auth.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-calendario-dia',
  templateUrl: './calendario-dia.component.html',
  styleUrls: ['./calendario-dia.component.css'],
  providers: [DatePipe]
})

@Component({
  selector: 'app-calendario-dia',
  templateUrl: './calendario-dia.component.html',
  styleUrls: ['./calendario-dia.component.css']
})

export class CalendarioDiaComponent implements OnInit {
 

  @Input() semanaProximaFiltroEntrada: string;

  dias: DiaModel[] = [];
  diasSubscription: Subscription;
  isUserSignedIn: boolean;

  fechaInicialFiltro = this.primerDiaCalendario();
  fechaInicialReservaEspacios = moment(environment.plazo.inicio);
  fechaFinalReservaEspacios = moment(environment.plazo.fin);

  fechaInicialPeridoInscripcion = moment(environment.plazoInscripcion.inicio);
  fechaFinalPeridoInscripcion = moment(environment.plazoInscripcion.fin);

  

  constructor(private router: Router, private diaService: DiaService, private reservaService: ReservaService,
    private authService: AuthService, private translate: TranslateService, private filtroSemanalService: FiltroSemanalService) { }

  ngOnInit() {
    this.translate.currentLang;
    this.getListadoweek(this.fechaInicialFiltro.format('YYYY-MM-DD'));    
  }


  // PUBLIC METHODS -----------------------------------------------------------
  public onClickGoToReserva(fecha: string) {
    this.router.navigate(['./reserva/'], { queryParams: { 'date': fecha } });
  }

  public onClickGoToAlta(fecha: string) {
    this.router.navigate(['./alta'], { queryParams: { 'date': fecha } });
  }

  public edit(reserva: any) {
    this.router.navigate(['alta/' + reserva.id]);
  }

  public delete(reserva: ReservaModel) {
    if (confirm(this.translate.instant('calendario-dia.confirmacion.borrar'))) {
      this.reservaService.delete(reserva);
      this.diasSubscription = this.diaService.getListadoWeek(this.fechaInicialFiltro.format('YYYY-MM-DD')).subscribe(data => {
        this.dias = data;
      });
    }
  }

  public getListadoweek(semanaFiltro?: string) {
    const semanaFiltroStr = semanaFiltro ? semanaFiltro : this.fechaInicialFiltro.format('YYYY-MM-DD');
    this.isUserSignedIn = this.authService.isUserSignedIn();
    this.diasSubscription = this.diaService.getListadoWeek(semanaFiltroStr, this.isUserSignedIn).subscribe(data => {
      this.dias = data;
    });
  }

  public esPosibleReservar(fechaStr: string): boolean {    

    let fecha = moment(fechaStr);
    let esPosibleReservar = false;
    let diasNoDisponibles = environment.dias.noDisponible;

    if (fecha >= this.fechaInicialReservaEspacios &&
      fecha <= this.fechaFinalReservaEspacios && !diasNoDisponibles.includes(fecha.format('dddd'))) {

      esPosibleReservar = true;
    }

    return esPosibleReservar;
  }

  public estaAbiertoPeriodoInscripcion(): boolean {
    
    let fecha =  moment();

    let abiertoPerido = false;
    let diasNoDisponibles = environment.dias.noDisponible;

    if (fecha >= this.fechaInicialPeridoInscripcion &&
      fecha <= this.fechaFinalPeridoInscripcion) {

        abiertoPerido = true;
    }

    return abiertoPerido;
  }

  public colorSolicitud(reserva): string{
    if(reserva.tipoId === 3){
      return "peticionEnCurso";
    }
    if(reserva.tipoId === 4){
      return "descartado";
    }
  }

  mostrarReserva(reserva) {
    if(reserva.tipoId == 1 && this.mostrarReservado) { // Reservado
      return true;
    }
    if(reserva.tipoId == 3 && this.mostrarEnCurso){ // En  Curso
      return true;
    }
    if(reserva.tipoId == 4 && this.mostrarDescartado) { // Descartado
      return true;
    }
    return false;
  }

  
  // PRIVATE METHODS ----------------------------------------------------------
  private primerDiaCalendario() {
    
    const hoy = moment();
    let primerDiaCalendario = hoy;
    const plazoInicio = moment(environment.plazo.inicio);
    
    if (hoy.isBefore(plazoInicio)) {
      primerDiaCalendario = plazoInicio;
    }

    return primerDiaCalendario;
  }

  // GETTER - SETTER --------------------
  get mostrarReservado(): boolean {
    return this.filtroSemanalService.mostrarReservado;
  }
  set mostrarReservado(value: boolean) {
    this.filtroSemanalService.mostrarReservado = value;
  }

  get mostrarEnCurso(): boolean {
    return this.filtroSemanalService.mostrarEnCurso;
  }
  set mostrarEnCurso(value: boolean) {
    this.filtroSemanalService.mostrarEnCurso = value;
  }

  get mostrarDescartado(): boolean {
    return this.filtroSemanalService.mostrarDescartado;
  }
  set mostrarDescartado(value: boolean) {
    this.filtroSemanalService.mostrarDescartado = value;
  }
}

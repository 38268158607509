import { Injectable, EventEmitter } from '@angular/core';
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  signedInUser = new EventEmitter<boolean>();

  constructor(private router: Router, private translate: TranslateService) {
    
    let firebaseAppDefined = false;

    setInterval(() => {

      // FIX: No Firebase App '[DEFAULT]' has been created: https://github.com/firebase/firebase-js-sdk/issues/258
      if (!firebaseAppDefined) {
        if (firebase.app()) {
          // Your code here
          if (firebase.app().options === undefined) {
            firebase.initializeApp(environment.firebaseAuth);
          }

          firebaseAppDefined = true;
        }
      }
    }, 150);
  }

  public signupUser(email: string, password: string) {
    // this.afAuth.auth.createUserWithEmailAndPassword(email, password)
    firebase.auth().createUserAndRetrieveDataWithEmailAndPassword(email, password)
      .catch(
        error => console.error(error)
      );
  }

  public signinUser(email: string, password: string) {

    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(
        response => {
          this.signedInUser.emit(true);
          this.router.navigate(['']);
        })
      .catch(
        error => {
          console.error(error);

          if (error.code === 'auth/user-not-found') {
            alert(this.translate.instant('auth.service.msg.usarioNoValido'));
          } else {
            alert(this.translate.instant('auth.service.msg.usarioNoValido'));
          }
        }
      );
  }

  public getSignedInUserEmitter() {
    return this.signedInUser;
  }  

  public signoutUser() {
    firebase.auth().signOut().then(response => {
      this.signedInUser.emit(false);
      this.router.navigate(['']);
    }).catch(error => {
      console.error(error);
    });
  }

  public isUserSignedIn(): boolean {
    let isSignedIn = false;
    isSignedIn = firebase && firebase.auth().currentUser !== null;
    return isSignedIn;
  }
}

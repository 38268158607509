import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { TipoModel } from '../model/tipo-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
    providedIn: 'root'
  })
export class TipoService {
    public tiposCollection: AngularFirestoreCollection<TipoModel>;

    constructor(private db: AngularFirestore) {
        this.tiposCollection = this.db.collection('/tipos');
    }

    public readAll(): Observable<TipoModel[]> {
        return this.tiposCollection.snapshotChanges().pipe(map(tipos => {
            return tipos.map( a => {
                const data = a.payload.doc.data() as TipoModel;
                data.id = parseInt(a.payload.doc.id);
                return data;
            });
        }));
    }
}
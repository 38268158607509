import { Component, OnInit, OnDestroy } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';

import { ReservaService } from '../shared/service/reserva.service';
import { ReservaModel } from '../shared/model/reserva-model';

@Component({
  selector: 'app-firestore-util',
  templateUrl: './firestore-util.component.html',
  styleUrls: ['./firestore-util.component.css']
})
export class FirestoreUtilComponent implements OnInit, OnDestroy {

  public reservas: ReservaModel[];
  public reservasCollection: AngularFirestoreCollection<ReservaModel>;
  private reservasSubscription: Subscription;


  constructor(private db: AngularFirestore, private reservaService: ReservaService) {
    this.reservas = [];
    this.reservasCollection = this.db.collection('/reservas');
  }

  ngOnInit() {
    this.reservasSubscription = this.reservaService.readAll().subscribe((data) => {
      this.reservas = data;
    });
    // this.reservas = this.readAllMock();
  }

  ngOnDestroy(): void {
    this.reservasSubscription.unsubscribe();
  }


  // PUBLIC METHODS -----------------------------------------------------------
  public resetReservas() {

    for (const reserva of this.reservas) {
      if (reserva.id !== '') {
        this.reservaService.delete(reserva);
      }
    }
    this.reservas = [];
  }

  public loadReservas() {
    const reservas = this.reservaService.readAllMock();
    for (const reserva of reservas) {
      this.reservaService.save(reserva);
    }
  }
}

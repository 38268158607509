import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class Utils {
    private local: string;
    constructor(private translateService: TranslateService) {}

    traducirTipos = (data: any): string => {
        this.local = this.translateService.currentLang;
        if (this.local == 'eu') {
            return data.descripcionEu;
        }
        if (this.local == 'en') {
            return data.descripcionEn;
        }
        return data.descripcionEs;
    }
}
import { ReservaModel } from './reserva-model';

export class DiaModel {

  fecha = '';
  tipoId = 2
  reservas: ReservaModel[] = [];
  id = '';

  constructor(fecha?: string, tipoId?: number, reservas?: ReservaModel[], id?: string) {

    this.fecha = fecha;
    this.tipoId = tipoId;
    this.reservas = reservas;
    this.id = id;
  }
}

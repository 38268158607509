import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { ReservaModel } from '../model/reserva-model';
import { Observable, Observer } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/internal/operators/map';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ReservaService {

  public reservas: ReservaModel[];
  public reservasCollection: AngularFirestoreCollection<ReservaModel>;
  private reservasObservable;

  constructor(private db: AngularFirestore) {
    this.reservas = [];
    this.reservasCollection = this.db.collection('/reservas');
  }

  public save(reserva: ReservaModel) {

    let id = reserva.id;

    if (id === '' || id === undefined) {
      id = this.db.createId();
      reserva.id = id;
      // this.reservasCollection.add(Object.assign({}, reserva));
      this.reservasCollection.doc<ReservaModel>(id).set(Object.assign({}, reserva));
    }

    this.reservasCollection.doc<ReservaModel>(id).set(Object.assign({}, reserva));
  }

  public read(id: string): AngularFirestoreDocument<ReservaModel> {
    return this.reservasCollection.doc<ReservaModel>(id);
  }

  public readAll(): Observable<ReservaModel[]> {

    this.reservasCollection = this.db.collection('/reservas', ref => {
      return ref.where('fecha', '>=', '2019-01-01').orderBy('fecha').orderBy('horaInicio');
    });
    this.reservasObservable = this.reservasCollection.snapshotChanges().pipe(map(reservas => {
      return reservas.map(a => {
        const data = a.payload.doc.data() as ReservaModel;
        data.id = a.payload.doc.id;
        this.reservas.push(data);
        return data;
      });
    }));

    return this.reservasObservable;
  }

  public readAllWeek(fechaSemana?: string): Observable<ReservaModel[]> {

    const now = fechaSemana?moment(fechaSemana):moment();
    const beginStr = now.format('YYYY-MM-DD');
    
    const end = now.add(6, 'days');
    let endStr = end.format('YYYY-MM-DD');

    this.reservasCollection = this.db.collection('/reservas', ref => {
      // return ref.where('fecha', '>=', '20000101').orderBy('fecha'); // .orderBy('tema');
      return ref.where('fecha', '>=', beginStr).where('fecha', '<=', endStr).orderBy('fecha').orderBy('horaInicio');
    });
    this.reservasObservable = this.reservasCollection.snapshotChanges().pipe(map(reservas => {
      return reservas.map(a => {
        const data = a.payload.doc.data() as ReservaModel;
        data.id = a.payload.doc.id;
        this.reservas.push(data);
        return data;
      });
    }));

    return this.reservasObservable;
  }

  public readAllNoDisponible(): Observable<ReservaModel[]> {
    // const noDisponible = 'NoDisponible';
    this.reservasCollection = this.db.collection('/reservas', ref => {
      // return ref.where('tipo', '==', noDisponible);
      return ref.where('tipoId', '==' , 2);
    });
    this.reservasObservable = this.reservasCollection.snapshotChanges().pipe(map(reservas => {
      return reservas.map(a => {
        const data = a.payload.doc.data() as ReservaModel;
        data.id = a.payload.doc.id;
        this.reservas.push(data);
        return data;
      });
    }));

    return this.reservasObservable;
  }

  public readOneBySolicitudId(solicitudId): Observable<ReservaModel[]> {
    this.reservasCollection = this.db.collection('/reservas', ref => {
      return ref.where('solicitudId', '==', solicitudId);
    });
    return this.reservasCollection.valueChanges();
  }


  public delete(reserva: ReservaModel) {
    this.reservasCollection.doc<ReservaModel>(reserva.id).delete();
  }

  public readAllMock(): ReservaModel[] {

    const reservas: ReservaModel[] = [];

    //reservas.push(new ReservaModel('1900-01-01', -1, '', '',));

    //TODO: ¡¡¡ CARGA PARA PRUEBA NO USAR EN PRODUCCION !!!:
    // reservas.push(new ReservaModel('2019-10-23', -1, '', '', 'NoDisponible'));
    // reservas.push(new ReservaModel('2019-10-24', 0, '10:30', '12:00', 'Disponible', 'Jornada', 'Entidad ficticia 1', 'Unai Romero', 'Calle del Museo 1, 4º', 'uromero@deustosistemas.net', '944000001', 'Presentación libro', 'Presentación del libro relacionado con ...', 'Microfono necesario.'));
    // reservas.push(new ReservaModel('2019-10-24', 0, '15:00', '18:00', 'Disponible', 'Charla', '', '', '', '', '', ''));
    // reservas.push(new ReservaModel('2019-10-25', 0, '15:00', '18:00', 'Disponible', 'Charla', '', '', '', '', '', ''));
    // reservas.push(new ReservaModel('2019-10-29', 0, '10:00', '11:00', 'Disponible', 'Workshop'));
    // reservas.push(new ReservaModel('2019-10-30', 0, '10:00', '11:00', 'PeticionEnCurso'));
    // reservas.push(new ReservaModel('2019-11-01', -1, '', '', 'NoDisponible'));
    // reservas.push(new ReservaModel('2019-10-02', 0, '15:00', '20:00', 'Disponible'));
    // reservas.push(new ReservaModel('2019-10-03', 0, '11:30', '12:00', 'Disponible', 'Jornada', 'Entidad ficticia 2', 'Aritza Cortajarena', 'Calle del Museo 2, 4º', 'acortajarena@deustosistemas.net', '944000002', 'Presentación', 'Presentación con motivo de ...', 'No se necesita material extra.'));
    
    // Precarga de lunes no disponibles:
    // reservas.push(new ReservaModel('2019-10-28', -1, '', '', 'NoDisponible', 'Jornada', '', '', '', '', '', '', '', '', 1, 0, '', ''));
    // reservas.push(new ReservaModel('2019-11-04', -1, '', '', 'NoDisponible', 'Jornada', '', '', '', '', '', '', '', '', 1, 0, '', ''));
    // reservas.push(new ReservaModel('2019-11-11', -1, '', '', 'NoDisponible', 'Jornada', '', '', '', '', '', '', '', '', 1, 0, '', ''));
    // reservas.push(new ReservaModel('2019-11-18', -1, '', '', 'NoDisponible', 'Jornada', '', '', '', '', '', '', '', '', 1, 0, '', ''));
    // reservas.push(new ReservaModel('2019-11-25', -1, '', '', 'NoDisponible', 'Jornada', '', '', '', '', '', '', '', '', 1, 0, '', ''));
    // reservas.push(new ReservaModel('2019-12-02', -1, '', '', 'NoDisponible', 'Jornada', '', '', '', '', '', '', '', '', 1, 0, '', ''));
    // reservas.push(new ReservaModel('2019-12-09', -1, '', '', 'NoDisponible', 'Jornada', '', '', '', '', '', '', '', '', 1, 0, '', ''));
    // reservas.push(new ReservaModel('2019-12-16', -1, '', '', 'NoDisponible', 'Jornada', '', '', '', '', '', '', '', '', 1, 0, '', ''));
    // reservas.push(new ReservaModel('2019-12-23', -1, '', '', 'NoDisponible', 'Jornada', '', '', '', '', '', '', '', '', 1, 0, '', ''));
    // reservas.push(new ReservaModel('2019-12-30', -1, '', '', 'NoDisponible', 'Jornada', '', '', '', '', '', '', '', '', 1, 0, '', ''));
    // reservas.push(new ReservaModel('2020-01-06', -1, '', '', 'NoDisponible', 'Jornada', '', '', '', '', '', '', '', '', 1, 0, '', ''));
    // reservas.push(new ReservaModel('2020-01-13', -1, '', '', 'NoDisponible', 'Jornada', '', '', '', '', '', '', '', '', 1, 0, '', ''));
    // reservas.push(new ReservaModel('2020-01-20', -1, '', '', 'NoDisponible', 'Jornada', '', '', '', '', '', '', '', '', 1, 0, '', ''));
    // console.log(JSON.stringify(reservas));

    return reservas;
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


/*export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyARoEtL4KGHWwQbSoOizky16sLFQ2JmSMM",
    authDomain: "desagutunzuria.firebaseapp.com",
    databaseURL: "https://desagutunzuria.firebaseio.com",
    projectId: "desagutunzuria",
    storageBucket: "desagutunzuria.appspot.com",
    messagingSenderId: "825097926926",
    appId: "1:825097926926:web:2f9a469877911007727f5f"
  },
  firebaseAuth: {
    apiKey: "AIzaSyARoEtL4KGHWwQbSoOizky16sLFQ2JmSMM",
    authDomain: "desagutunzuria.firebaseapp.com",
  },
  emailSender: {
    url: 'https://us-central1-desagutunzuria.cloudfunctions.net/sendMail'
  },
  plazo: {
    inicio: '2022-03-29',
    fin: '2022-04-02'
  },
  plazoInscripcion: {
    inicio: '2022-02-17',
    fin: '2022-03-20'
  },
  horarioInscripcion: {
    diasTarde: '2022-03-29,2022-03-30,2022-03-31,2022-04-01',
    diasManana: '2022-04-02',
    horarioTardeInicio: '16:30',
    horarioTardeFin: '19:00',
    horarioMananaInicio: '12:00',
    horarioMananaFin: '13:30',
  },
  dias:{
    noDisponible: ['Monday', 'Sunday']
  }
};*/

export const environment = {
  production: true,
  firebase: {
      apiKey: "AIzaSyD9CB2S4qgt049B7e_9Xdkp3qv43KOM4UQ",
      authDomain: "gutunzuria.firebaseapp.com",
      databaseURL: "https://gutunzuria.firebaseio.com",
      projectId: "gutunzuria",
      storageBucket: "gutunzuria.appspot.com",
      messagingSenderId: "328927995631",
      appId: "1:328927995631:web:075a831822b585e3db9d1a"
  },
  firebaseAuth: {
      apiKey: "AIzaSyD9CB2S4qgt049B7e_9Xdkp3qv43KOM4UQ",
      authDomain: "gutunzuria.firebaseapp.com",
  },
  emailSender: {
      url: 'https://us-central1-gutunzuria.cloudfunctions.net/sendMail'
  },
  plazo: {
      inicio: '2022-03-29',
      fin: '2022-04-01'
  },
  plazoInscripcion: {
      inicio: '2022-02-22',
      fin: '2022-03-20'
  },
  horarioInscripcion: {
    diasTarde: '2022-03-29,2022-03-30,2022-03-31,2022-04-01',
    diasManana: '1970-01-01',
    horarioTardeInicio: '17:00',
    horarioTardeFin: '19:00',
    horarioMananaInicio: '12:00',
    horarioMananaFin: '13:00',
    },
  dias:{
      noDisponible: ['Monday', 'Sunday']
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
